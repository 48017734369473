var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "stat-main"
  }, [_c('div', {
    staticClass: "stat-list"
  }, [_c('div', {
    staticClass: "stat-item"
  }, [_c('div', {
    staticClass: "stat-item-tit"
  }, [_vm._v("总用户数")]), _c('div', {
    staticClass: "stat-item-val"
  }, [_vm._v(_vm._s(_vm.statData.user_total || 0))]), _c('div', {
    staticClass: "stat-item-opt"
  }, [_c('div', {
    staticClass: "btn",
    on: {
      "click": function ($event) {
        return _vm.getStepData(0);
      }
    }
  }, [_vm.stepLoading[0] ? [_vm._v("读取中...")] : [_vm._v("刷新")]], 2)])])]), _c('div', {
    staticClass: "stat-list"
  }, [_c('div', {
    staticClass: "stat-item"
  }, [_c('div', {
    staticClass: "stat-item-tit"
  }, [_vm._v("今日新增用户数")]), _c('div', {
    staticClass: "stat-item-val"
  }, [_vm._v(_vm._s(_vm.statData.today_total || 0))]), _c('div', {
    staticClass: "stat-item-opt"
  }, [_c('div', {
    staticClass: "btn",
    on: {
      "click": function ($event) {
        return _vm.getStepData(1);
      }
    }
  }, [_vm.stepLoading[1] ? [_vm._v("读取中...")] : [_vm._v("刷新")]], 2)])]), _c('div', {
    staticClass: "stat-item"
  }, [_c('div', {
    staticClass: "stat-item-tit"
  }, [_vm._v("昨日新增用户数")]), _c('div', {
    staticClass: "stat-item-val"
  }, [_vm._v(_vm._s(_vm.statData.yestoday_total || 0))]), _c('div', {
    staticClass: "stat-item-opt"
  }, [_c('div', {
    staticClass: "btn",
    on: {
      "click": function ($event) {
        return _vm.getStepData(2);
      }
    }
  }, [_vm.stepLoading[2] ? [_vm._v("读取中...")] : [_vm._v("刷新")]], 2)])]), _c('div', {
    staticClass: "stat-item"
  }, [_c('div', {
    staticClass: "stat-item-tit"
  }, [_vm._v("3日新增用户数")]), _c('div', {
    staticClass: "stat-item-val"
  }, [_vm._v(_vm._s(_vm.statData.three_total || 0))]), _c('div', {
    staticClass: "stat-item-opt"
  }, [_c('div', {
    staticClass: "btn",
    on: {
      "click": function ($event) {
        return _vm.getStepData(3);
      }
    }
  }, [_vm.stepLoading[3] ? [_vm._v("读取中...")] : [_vm._v("刷新")]], 2)])]), _c('div', {
    staticClass: "stat-item"
  }, [_c('div', {
    staticClass: "stat-item-tit"
  }, [_vm._v("7日新增用户数")]), _c('div', {
    staticClass: "stat-item-val"
  }, [_vm._v(_vm._s(_vm.statData.week_total || 0))]), _c('div', {
    staticClass: "stat-item-opt"
  }, [_c('div', {
    staticClass: "btn",
    on: {
      "click": function ($event) {
        return _vm.getStepData(4);
      }
    }
  }, [_vm.stepLoading[4] ? [_vm._v("读取中...")] : [_vm._v("刷新")]], 2)])]), _c('div', {
    staticClass: "stat-item"
  }, [_c('div', {
    staticClass: "stat-item-tit"
  }, [_vm._v("1个月新增用户数")]), _c('div', {
    staticClass: "stat-item-val"
  }, [_vm._v(_vm._s(_vm.statData.month_total || 0))]), _c('div', {
    staticClass: "stat-item-opt"
  }, [_c('div', {
    staticClass: "btn",
    on: {
      "click": function ($event) {
        return _vm.getStepData(5);
      }
    }
  }, [_vm.stepLoading[5] ? [_vm._v("读取中...")] : [_vm._v("刷新")]], 2)])])]), _c('div', {
    staticClass: "stat-list"
  }, [_c('div', {
    staticClass: "stat-item"
  }, [_c('div', {
    staticClass: "stat-item-tit"
  }, [_vm._v("今日登录用户")]), _c('div', {
    staticClass: "stat-item-val"
  }, [_vm._v(_vm._s(_vm.statData.today_login || 0))]), _c('div', {
    staticClass: "stat-item-opt"
  }, [_c('div', {
    staticClass: "btn",
    on: {
      "click": function ($event) {
        return _vm.getStepData(6);
      }
    }
  }, [_vm.stepLoading[6] ? [_vm._v("读取中...")] : [_vm._v("刷新")]], 2)])]), _c('div', {
    staticClass: "stat-item"
  }, [_c('div', {
    staticClass: "stat-item-tit"
  }, [_vm._v("昨日登录用户")]), _c('div', {
    staticClass: "stat-item-val"
  }, [_vm._v(_vm._s(_vm.statData.yestoday_login || 0))]), _c('div', {
    staticClass: "stat-item-opt"
  }, [_c('div', {
    staticClass: "btn",
    on: {
      "click": function ($event) {
        return _vm.getStepData(7);
      }
    }
  }, [_vm.stepLoading[7] ? [_vm._v("读取中...")] : [_vm._v("刷新")]], 2)])]), _c('div', {
    staticClass: "stat-item"
  }, [_c('div', {
    staticClass: "stat-item-tit"
  }, [_vm._v("3日登录用户")]), _c('div', {
    staticClass: "stat-item-val"
  }, [_vm._v(_vm._s(_vm.statData.three_login || 0))]), _c('div', {
    staticClass: "stat-item-opt"
  }, [_c('div', {
    staticClass: "btn",
    on: {
      "click": function ($event) {
        return _vm.getStepData(8);
      }
    }
  }, [_vm.stepLoading[8] ? [_vm._v("读取中...")] : [_vm._v("刷新")]], 2)])])]), _c('div', {
    staticClass: "btns"
  }, [_c('div', {
    staticClass: "btn",
    on: {
      "click": function ($event) {
        !_vm.isLoading && _vm.onRefresh();
      }
    }
  }, [_vm.isLoading ? [_vm._v("读取中...")] : [_vm._v("刷新")]], 2)])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };