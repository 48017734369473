import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
import loadConfig from "@Web3WalletConfig/config";
export default {
  name: "gift",
  computed: {
    ...walletVuex.mapState(["isMobile", "theme", 'setting'])
  },
  data() {
    return {
      loadConfig,
      isLock: false,
      list: {},
      popMineTaskShow: false,
      modifyData: {
        "icon": ""
      },
      isLoading: false
    };
  },
  methods: {
    async getData() {
      this.isLoading = true;
      let rs = await this.$http.post("/mine/task", {});
      this.isLoading = false;
      if (rs.code == 0) {
        this.list = rs.data;
      }
    },
    async onCreate() {
      this.modifyData = {
        "icon": "",
        'status': "1",
        "sort": "0",
        "pre_type": 0
      };
      this.popMineTaskShow = true;
    },
    async onModify(item) {
      this.modifyData = item;
      this.popMineTaskShow = true;
    },
    async onRemove(item) {
      this.isLoading = true;
      let rs = await this.$http.post("/mine/task/remove", {
        id: item.id
      });
      this.isLoading = false;
      if (rs.code == 0) {
        this.list = rs.data;
      }
    },
    cutStr(str) {
      if (str) return str.substring(0, 10);
    },
    onRefresh() {
      this.getData();
    }
  },
  mounted() {
    this.getData();
  }
};