var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "task-main",
    class: _vm.isMobile && 'wap'
  }, [_c('div', {
    staticClass: "btns"
  }, [_c('div', {
    staticClass: "btn btn-create",
    on: {
      "click": _vm.onCreate
    }
  }, [_vm._v("创建新任务")]), _c('div', {
    staticClass: "btn btn-create",
    on: {
      "click": _vm.onRefresh
    }
  }, [_vm.isLoading ? [_vm._v("读取中...")] : [_vm._v("刷新")]], 2)]), _c('div', {
    staticClass: "task-list"
  }, [_vm._m(0), _vm._l(_vm.list, function (item, index) {
    return _c('div', {
      staticClass: "task-list-item"
    }, [_c('div', {
      staticClass: "s id"
    }, [_vm._v(" " + _vm._s(item.id) + " ")]), _c('div', {
      staticClass: "s id"
    }, [_vm._v(" " + _vm._s(item.category_id) + " ")]), _c('div', {
      staticClass: "s label"
    }, [_vm._v(" " + _vm._s(item.name) + " ")]), _c('div', {
      staticClass: "s label"
    }, [_vm._v(" " + _vm._s(item.short_name) + " ")]), _c('div', {
      staticClass: "s icon"
    }, [item.icon ? _c('img', {
      attrs: {
        "src": item.icon,
        "height": "30px"
      }
    }) : _vm._e()]), _c('div', {
      staticClass: "s url"
    }, [_vm._v(" " + _vm._s(item.description) + " ")]), _c('div', {
      staticClass: "s num"
    }, [_vm._v(" " + _vm._s(item.initial_cost) + " ")]), _c('div', {
      staticClass: "s num"
    }, [_vm._v(" " + _vm._s(item.initial_earnings) + " ")]), _c('div', {
      staticClass: "s num"
    }, [item.pre_type == 0 ? [_vm._v("无条件")] : _vm._e(), item.pre_type == 1 ? [_vm._v("关联其他任务")] : _vm._e(), item.pre_type == 2 ? [_vm._v("邀请用户数")] : _vm._e()], 2), _c('div', {
      staticClass: "s num"
    }, [_vm._v(" " + _vm._s(item.pre_task_id) + " ")]), _c('div', {
      staticClass: "s num"
    }, [_vm._v(" " + _vm._s(item.pre_task_level) + " ")]), _c('div', {
      staticClass: "s num"
    }, [_vm._v(" " + _vm._s(item.required_invitation_count) + " ")]), _c('div', {
      staticClass: "s status",
      class: `status_${item.status}`
    }, [_vm._v(" " + _vm._s(item.status == 0 ? '禁用' : '启用') + " ")]), _c('div', {
      staticClass: "s sort"
    }, [_vm._v(" " + _vm._s(item.sort) + " ")]), _c('div', {
      staticClass: "s create"
    }, [_vm._v(" " + _vm._s(_vm.cutStr(item.created_at)) + " ")]), _c('div', {
      staticClass: "s opt"
    }, [_c('div', {
      staticClass: "btn",
      on: {
        "click": function ($event) {
          return _vm.onModify(item);
        }
      }
    }, [_vm._v("编辑")])])]);
  })], 2), _c('pop-mine-task', {
    attrs: {
      "show": _vm.popMineTaskShow
    },
    on: {
      "update:show": function ($event) {
        _vm.popMineTaskShow = $event;
      }
    }
  })], 1);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "task-list-item"
  }, [_c('div', {
    staticClass: "s id"
  }, [_vm._v(" 编号 ")]), _c('div', {
    staticClass: "s id"
  }, [_vm._v(" 分类编号 ")]), _c('div', {
    staticClass: "s label"
  }, [_vm._v(" 名称 ")]), _c('div', {
    staticClass: "s label"
  }, [_vm._v(" 短名称 ")]), _c('div', {
    staticClass: "s icon"
  }, [_vm._v(" ICON ")]), _c('div', {
    staticClass: "s url"
  }, [_vm._v(" 描述 ")]), _c('div', {
    staticClass: "s num"
  }, [_vm._v(" 初始成本 ")]), _c('div', {
    staticClass: "s num"
  }, [_vm._v(" 初始收益 ")]), _c('div', {
    staticClass: "s num"
  }, [_vm._v(" 前置条件 ")]), _c('div', {
    staticClass: "s num"
  }, [_vm._v(" 前置任务ID ")]), _c('div', {
    staticClass: "s num"
  }, [_vm._v(" 前置任务等级 ")]), _c('div', {
    staticClass: "s num"
  }, [_vm._v(" 邀请用户数 ")]), _c('div', {
    staticClass: "s status"
  }, [_vm._v(" 状态 ")]), _c('div', {
    staticClass: "s sort"
  }, [_vm._v(" 排序 ")]), _c('div', {
    staticClass: "s create"
  }, [_vm._v(" 添加时间 ")]), _c('div', {
    staticClass: "s opt"
  })]);
}];
export { render, staticRenderFns };